<template>
  <div class="wrapper">
    <v-select
      ref="vSelect"
      :loading="asyncQueryActive"
      :onSearch="onSearch"
      :inputId="getFieldID(schema)"
      :multiple="schema.multiple"
      :value="context"
      :onChange="onChange"
      :disabled="schema.readonly || schema.disabled"
      :options="options"
      :placeholder="schema.placeholder"
      :filterable="false"
    >
      <div
        class="d-flex w-100 justify-content-between align-items-center select-item"
        slot="selected-option-container"
        slot-scope="{ option, deselect }"
      >
        <div class="d-flex justify-content-start align-items-center pl-2">
          <img
            v-if="option.default_image"
            :src="
              option.default_image.image
                ? option.default_image.image.file_url
                : '/img/no-image.png'
            "
            class="product-image mr-2"
          />
          <div class="pl-2">
            <div class="row">
              <div class="col">
                <p class="m-0">
                  <strong>{{ option.label }}</strong>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <p
                  class="m-0"
                  v-if="option.product_number"
                >Product Number: {{ option.product_number }}</p>
                <p
                  class="m-0"
                  v-if="option.supplier_product_number"
                >Supplier Product Number: {{ option.supplier_product_number }}</p>
              </div>
              <div class="col-6">
                <p class="m-0" v-if="option.available">Available: {{ option.available }}</p>
                <p
                  class="m-0"
                  v-if="option.sellable_stock"
                >Sellable Stock: {{ option.sellable_stock }}</p>
                <p class="m-0" v-if="!option.sellable_stock">Sellable Stock: 0</p>
                <p
                  class="m-0"
                  v-if="option.available_stock"
                >Available Stock: {{ option.available_stock }}</p>
                <p class="m-0" v-if="option.available_stock_manufacturer">
                  Available Stock Manufacturer:
                  {{ option.available_stock_manufacturer }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-default btn-trash text-danger" @click.prevent="deselect(option)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>

      <div
        class="d-flex justify-content-start align-items-center p-0"
        slot="option"
        slot-scope="{
          label,
          default_image,
          product_number,
          supplier_product_number,
          available,
          available_stock,
          available_stock_manufacturer,
          sellable_stock,
          delivery_days,
        }"
      >
        <img
          v-if="default_image"
          :src="
            default_image.image
              ? default_image.image.file_url
              : '/img/no-image.png'
          "
          class="product-image"
        />
        <div class="pl-2">
          <div class="row">
            <div class="col">
              <p class="m-0">
                <strong>{{ label }}</strong>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="m-0" v-if="product_number">Product Number: {{ product_number }}</p>
              <p
                class="m-0"
                v-if="supplier_product_number"
              >Supplier Product Number: {{ supplier_product_number }}</p>
              <p class="m-0 h4 pb-2 font-weight-bold">
                <i class="fa fa-truck"></i> &nbsp;
                <span
                  v-if="sellable_stock >= 1 && (available_stock_manufacturer === 0 || available_stock_manufacturer >= 1)"
                  class="text-success"
                >24h</span>
                <span v-else-if="delivery_days">{{ delivery_days + ' days' }}</span>
                <span v-else class="text-danger">???</span>
              </p>
            </div>
            <div class="col">
              <p class="m-0">&nbsp;</p>
              <p class="m-0" v-if="available">Available: {{ available }}</p>
              <p class="m-0" v-if="sellable_stock">Sellable Stock: {{ sellable_stock }}</p>
              <p class="m-0" v-if="!sellable_stock">Sellable Stock: 0</p>
              <p class="m-0" v-if="available_stock">Available Stock: {{ available_stock }}</p>
              <p
                class="m-0"
                v-if="available_stock_manufacturer"
              >Available Stock Manufacturer: {{ available_stock_manufacturer }}</p>
            </div>
          </div>
        </div>
      </div>
    </v-select>
  </div>
</template>

<script>
//https://sagalbot.github.io/vue-select/docs/Api/Props.html
import VueFormGenerator from 'vue-form-generator';

export default {
  mixins: [VueFormGenerator.abstractField],
  data() {
    return {
      options: [],
      context: null,
      asyncQueryActive: false,
      lastValue: null,
    };
  },
  methods: {
    onSearch(searchValue) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        this.doSearch(searchValue);
      }, 500);
    },
    async doSearch(keyword) {
      this.asyncQueryActive = true;

      const queryIsFunction = typeof this.schema.query === 'function';
      let serverResponse;

      if (queryIsFunction) {
        serverResponse = await this.schema.query(
          this.$apolloHelper,
          this.model,
          keyword
        );
      } else {
        serverResponse = await this.$apolloHelper({
          fallbackData: [],
          query: Array.isArray(this.schema.query)
            ? this.schema.query[0]
            : this.schema.query,
          force: true,
          variables: {
            page: 1,
            filters: {
              keywords: [keyword],
            },
            language_id: 1,
            sorting_option_id: 2,
          },
        });
      }

      const arr = Array.isArray(this.schema.query)
        ? serverResponse[this.schema.query[1]]
        : serverResponse;

      const options = arr && this.mapForVueSelect(arr);

      this.options = options;
      this.asyncQueryActive = false;
    },
    mapForVueSelect(arr) {
      const result = arr.map((item) => ({
        ...item,
        label: `${this.schema.mappingPath
          .split('.')
          .reduce(
            (result, key) => (result && result[key]) || 'No value',
            item
          )} ${
          this.schema.secondMappingPath
            ? `(${item[this.schema.secondMappingPath]})`
            : ''
        } ${
          this.schema.thirdMappingPath
            ? `(${item[this.schema.thirdMappingPath]})`
            : ''
        }`,
      }));

      if (this.isIntegerModel || !this.value || !this.value.length) {
        return result;
      } else {
        const hash = {};
        result.forEach((item) => (hash[item.id] = item));
        // merging original object state into received from server in order to save the table references if any
        this.value.forEach(
          (item) => (hash[item.id] = { ...hash[item.id], ...item })
        );
        return Object.values(hash);
      }
    },
    onChange(newValue) {
      this.lastValue = newValue;
      let result = !newValue
        ? null
        : !this.schema.multiple
        ? newValue.id
        : this.isIntegerModel
        ? newValue.map((item) => item.id)
        : newValue;
      if (this.schema.multiple && result) {
        result.isVueSelect = true;
      }
      this.value = result || null;
    },
    // Expects array when multiple === true, and id: Int, when false
    async setContext() {
      if (!this.value) {
        this.context = [];
        return;
      }

      if (
        this.schema.multiple &&
        (this.value.length === 0 || this.value.isVueSelect)
      ) {
        // Avoiding infinite loop for multiple because of non-prmimitive data type
        return;
      }

      if (
        this.isIntegerModel &&
        !this.schema.multiple &&
        this.lastValue &&
        typeof this.lastValue === 'object' &&
        this.lastValue.id === this.value
      ) {
        // Avoiding extra pre-fetch of products list when model is not object type
        return;
      }

      const serverResponse = await this.$apolloHelper({
        fallbackData: [],
        query: this.schema.prefetch,
        force: true,
        variables: {
          ids: this.isIntegerModel
            ? this.value
            : this.value.map((item) => item.id),
        },
      });

      const result = serverResponse && this.mapForVueSelect(serverResponse);
      this.context = this.schema.multiple
        ? result
        : result.length
        ? result[0]
        : null;
    },
  },
  computed: {
    isIntegerModel() {
      return (
        this.schema.validatorName &&
        ['integer', 'integerArray', 'stringArray'].includes(
          this.schema.validatorName
        )
      );
    },
  },
  /* mounted() {
    // For development: to hold the select dropdown unclosed
    // https://github.com/sagalbot/vue-select/blob/master/src/components/Select.vue
    this.$refs.vSelect._computedWatchers.dropdownOpen.getter = () => true
  }, */
  watch: {
    value: {
      handler: 'setContext',
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.field-product-select {
  .disabled {
    .btn-trash {
      display: none;
    }
    input[type='search'] {
      display: none;
    }
    .select-item {
      border-bottom: 0;
    }
  }
  .select-item {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #e4e4e4;
    &:hover {
      background-color: #f0f3f5;
    }
    .btn-trash {
      font-size: 1.2rem;
    }
  }

  .v-select input[type='search'].form-control {
    border: 1px solid rgba(60, 60, 60, 0.26);
  }

  .selected-options-wrapper-container {
    width: 100%;
    max-height: 250px;
    overflow: auto;
  }

  .product-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 20px;
  }
}
</style>
