<template>
  <div class="file-upload-container w-100">
    <!--UPLOAD-->
    <div
      class="dropbox"
      v-if="!hasImages || schema.multiple"
      :class="{ 'center-dropbox': schema.size > 1 }"
      :style="{ 'min-height': `${schema.size * 50}px` }"
    >
      <input
        type="file"
        :multiple="schema.multiple"
        :name="schema.model"
        @change="filesChange($event.target.name, $event.target.files)"
        :accept="schema.accept ? schema.accept : 'image/*'"
        :style="{ 'min-height': `${schema.size * 50}px` }"
        :id="getFieldID(schema)"
        :required="schema.required"
        :value="''"
      />

      <!-- The above :value="''" is a trick for multi-file upload to be able to upload same file again -->

      <p v-if="!hasImages || schema.multiple">
        <span class="btn btn-primary dropdown-toggle">{{
          schema.multiple ? "Select files" : "Select a file"
        }}</span
        >&nbsp;&nbsp; or drag here
      </p>
    </div>
    <!--SUCCESS-->
    <div v-if="imagePreviewStrings" class="image-preview">
      <strong>{{ imagePreviewStrings.length }} file(s) uploaded</strong
      ><span class="divider"> | </span
      ><a
        class="upload-again"
        @click.stop.prevent="reset()"
        href="#"
        v-if="replace"
        >Upload again / remove file</a
      ><span v-else
        >Replacing file not supported, please delete and add new.</span
      >
      <ul class="list-unstyled">
        <li
          class="image-list"
          v-for="(item, index) in imagePreviewStrings"
          :key="index"
        >
          <a :href="item" target="_blank">
            <img :src="item" alt="Image"/>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="schema.actionLabel" class="mt-1 text-left">
      <button @click.prevent="schema.handler(schema, value)">
        {{ schema.actionLabel }}
      </button>
    </div>
  </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
const filePlaceholderImage = "/img/file.png";

export default {
  mixins: [VueFormGenerator.abstractField],
  name: "app",
  data() {
    return {
      imagePreviewStrings: null
    };
  },
  computed: {
    hasImages() {
      return this.imagePreviewStrings;
    },
    replace() {
      if (typeof this.schema.replace !== "undefined") {
        return this.schema.replace;
      }

      return true;
    }
  },
  methods: {
    reset() {
      this.imagePreviewStrings = null;
      this.updateModel(null);
    },
    updateModel(data) {
      if (this.schema.multiple && data) {
        this.value = Array.isArray(this.value) ? this.value.concat(data) : data;
      } else {
        this.value = data;
      }
    },
    async setPreview(data) {
      const previewStrings = [];
      const fileList = this.schema.multiple ? data : [data];
      for (const file of fileList) {
        if (this.$helpers.isTypeImage(file.type)) {
          const previewString = await this.$helpers.filePreviewHelper(file);
          previewStrings.push(previewString);
        } else {
          previewStrings.push(filePlaceholderImage);
        }
      }
      this.imagePreviewStrings = previewStrings;
    },
    filesChange(fieldName, fileList) {
      if (!fileList.length) return;
      const data = this.schema.multiple ? Object.values(fileList) : fileList[0];
      this.updateModel(data);
    },
    onValueChange(newValue, oldValue) {
      if (!newValue) {
        this.imagePreviewStrings = null;
      } else if (newValue && newValue.file_url) {
        const extRaw = newValue.file_url.split(".");
        const ext = extRaw[extRaw.length - 1].toLowerCase();
        this.imagePreviewStrings = [
          this.$helpers.isTypeImage(ext)
            ? `${newValue.file_url}?width=800&height=600&quality=85&dpr=1&format=pjpg&auto=webp&fit=bounds&io=true}`
            : filePlaceholderImage
        ];
      } else if (newValue instanceof File) {
        this.setPreview(newValue);
      } else if (
        this.schema.multiple &&
        Array.isArray(newValue) &&
        newValue.length &&
        newValue[0] instanceof File
      ) {
        // If first array item is instanceof File, we assume that the whole array is filled with Files
        this.setPreview(newValue);
      } else if (
        this.schema.multiple &&
        Array.isArray(newValue) &&
        newValue.length &&
        newValue[0].file_url
      ) {
        this.imagePreviewStrings = newValue.map(file => {
          const extRaw = file.file_url.split(".");
          const ext = extRaw[extRaw.length - 1].toLowerCase();
          return this.$helpers.isTypeImage(ext)
            ? file.file_url
            : filePlaceholderImage;
        });
      }
    }
  },
  watch: {
    value: {
      handler: "onValueChange",
      immediate: true
    }
  }
};
</script>

<style lang="scss">
.image-preview {
  margin-top: 8px;
}

.form-group.disabled {
  .file-upload-container {
    .image-preview {
      .divider,
      .upload-again {
        display: none;
      }
    }
  }
}

.dropbox {
  outline: 2px dashed rgb(74, 127, 145); /* the dash box */
  outline-offset: -5px;
  background: white;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
  &.center-dropbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &:hover {
    background: lightcyan; /* when mouse over to the drop zone, change color */
  }
  p {
    font-size: 1.2em;
    cursor: pointer;
    margin: 0;
  }
  input {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.image-list {
  &:first-child {
    margin-top: 10px;
  }

  max-width: 800px;
  position: relative;
  box-shadow: 0 30px 90px -20px rgba(0, 0, 0, 0.3), 0 0 1px #a2a9b1;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
