// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es/promise';
import 'core-js/es/string';
import 'core-js/es/array';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import vfg from '@/plugins/vfg';
import apolloHelper from '@/plugins/apollo-helper';
import helpers from '@/plugins/helpers';
import genericTableGrid from '@/plugins/generic-table-grid';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueTrumbowyg from 'vue-trumbowyg';
import VueMoment from 'vue-moment';
import VuePrismEditor from 'vue-prism-editor';
import 'prismjs';
import 'prismjs/themes/prism.css';
import VueMeta from 'vue-meta';

Vue.use(BootstrapVue);
Vue.use(apolloHelper);
Vue.use(helpers);
Vue.use(genericTableGrid);
Vue.use(vfg);
Vue.use(VueTrumbowyg);
Vue.use(VueMoment);
Vue.use(VueMeta);

Vue.component('prism-editor', VuePrismEditor);

Vue.filter('round', function (value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
});

Vue.filter('currency', (v, isCurrency = true) => {
  return (+v).toFixed(2);

  // return isCurrency
  //   ? `${(+v).toLocaleString('pl-PL', {
  //       style: 'currency',
  //       currency: 'PLN',
  //       minimumFractionDigits: 0,
  //     })}`
  //   : (+v).toFixed(2);
});

Vue.filter('plusMinusValue', function (value) {
  if (value < 0) {
    return `<span class="text-danger">${value}</span>`;
  } else {
    return `<span class="text-success">+${value}</span>`;
  }
});

Vue.filter('date', function (value) {
  if (!value) {
    return '';
  }

  return new Date(+value).toLocaleDateString('pl-PL', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
});

const eventsHub = new Vue();

new Vue({
  el: '#app',
  router,
  store,
  components: {
    App,
  },
  template: '<App/>',
});
