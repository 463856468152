<template>
  <div>
    <router-view />
    <toast position="ne"></toast>
    <FullScreenPreview v-if="$store.state.fullScreenPreview" />
  </div>
</template>

<script>
import { Toast } from 'vuex-toast';
import FullScreenPreview from '@/components/FullScreenPreview';

const BANNER_STORAGE_KEY = 'notification_banner_closed_at_v2';
const SIX_HOURS_IN_MS = 6 * 60 * 60 * 1000;

export default {
  name: 'app',
  metaInfo: {
    title: 'No Page',
    titleTemplate: '%s | wonder Backoffice',
  },
  components: {
    Toast,
    FullScreenPreview,
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import 'assets/scss/style';
@import '~trumbowyg/dist/ui/trumbowyg.css';

/* Import Font Awesome Icons Set */
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';

html,
body {
  font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

#notification-banner {
  position: fixed;
  left: 0;
  right: 0;
  background-color: orange;
  color: white;
  padding: 20px 5px;
  text-align: center;
  transition: top 0.5s ease-in-out;
  z-index: 9999;
  font-size: 18px;
}

#notification-banner a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

#close-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-weight: bold;
  font-size: 25px;
}
</style>
