import store from '@/store';
import slugify from 'slugify';
import { user } from '../auth';

export default {
  objectToHtmlTable(sourceObject) {
    if (typeof sourceObject === 'string') {
      sourceObject = JSON.parse(sourceObject);
    }

    let table =
      '<div class="table-responsive"><table class="table-striped table-bordered">';

    Object.keys(sourceObject).forEach(function(key) {
      table += `<tr><th style="text-transform: capitalize; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100px">${key}</th><td style="max-width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${sourceObject[key]}</td></tr>`;
    });

    table += '</table></div>';

    return table;
  },
  currencyFormatter(v) {
    return (
      v &&
      `${(+v).toLocaleString('pl-PL', {
        style: 'currency',
        currency: 'PLN',
        minimumFractionDigits: 0,
      })}`
    );
  },
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  },
  log(...args) {
    console.log(...args.map(item => JSON.stringify(item, null, 2)));
  },
  async logDelayed(...args) {
    await this.delay(2500);
    this.log(...args);
  },
  delay(time = 5000, shouldReject = false) {
    return new Promise((res, rej) => {
      setTimeout(shouldReject ? rej : res, time);
    });
  },
  take(obj, props = []) {
    const result = {};
    props.forEach(prop => (result[prop] = obj[prop]));
    return result;
  },
  getArrayFromString(data, divider = ',') {
    return data
      .split(divider)
      .map(item => item.trim())
      .filter(item => !!item);
  },
  stringArrayToIntArray(data) {
    return data.filter(item => !isNaN(item)).map(item => +item);
  },
  omitTypename(key, value) {
    return key === '__typename' ? undefined : value;
  },
  deepClone(data, settings = {}) {
    let result;
    try {
      if (settings.omitTypename) {
        result = JSON.parse(JSON.stringify(data), this.omitTypename);
      } else {
        result = JSON.parse(JSON.stringify(data));
      }
    } catch (e) {
      if (Array.isArray(data)) {
        result = [];
      } else {
        result = {};
      }
    }
    return result;
  },
  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  saveToFile(data) {
    const existingLink = document.getElementById('dinamicLinkId');
    if (existingLink) {
      existingLink.remove();
    }
    const textData = typeof data === 'string' ? data : JSON.stringify(data);
    const MIME_TYPE = 'text/plain';
    let bb = new Blob([textData], {
      type: MIME_TYPE,
    });
    let a = document.createElement('a');
    a.download = 'Data ' + Date.now() + '.json';
    a.href = window.URL.createObjectURL(bb);
    a.textContent = 'Download ready';
    a.dataset.downloadurl = [MIME_TYPE, a.download, a.href].join(':');
    a.id = 'dinamicLinkId';
    document.body.appendChild(a);
    document.getElementById('dinamicLinkId').click();
  },
  findArrayIndexByObjectProp(arrayOfObjects, key, value) {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i][key] === value) {
        return i;
      }
    }
    return -1;
  },
  sortByKey(array, key, asc = true) {
    // https://stackoverflow.com/a/14463464/6880261
    array.sort(function(a, b) {
      let x = a[key];
      let y = b[key];

      if (typeof x == 'string') {
        x = ('' + x).toLowerCase();
      }
      if (typeof y == 'string') {
        y = ('' + y).toLowerCase();
      }
      if (asc) {
        return x < y ? -1 : x > y ? 1 : 0;
      } else {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  },
  // https://stackoverflow.com/questions/5598743/finding-elements-position-relative-to-the-document
  getCoords(elem) {
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft =
      window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  },
  scollIntoView(selector) {
    const el = document.querySelector(selector);
    if (el) {
      // Added setTimeout because on some nested language tabs it takes more time to expand the b-collapse
      setTimeout(() => {
        const coords = this.getCoords(el);
        window.scrollTo({
          top: coords.top - 100, // header size
          behavior: 'smooth',
        });
      }, 500);
    }
  },
  infiniteLoopHelper(key, msg = 'Infinite loop', loop = 10) {
    window[key] = window[key] ? window[key] + 1 : 1;
    if (window[key] >= loop) {
      store.dispatch('informUserError', msg);
      throw new Error(msg);
    }
  },
  handleBodyOverflow(
    shouldPreventBodyScroll,
    stopBodyScrollEnabling = false,
    blurSelectors = false
  ) {
    let blurElements = null;
    if (blurSelectors) {
      let selectors = Array.isArray(blurSelectors)
        ? blurSelectors
        : [blurSelectors];
      blurElements = [];
      selectors.forEach(item =>
        blurElements.push(document.querySelector(item))
      );
      blurElements = blurElements.filter(item => !!item);
    }
    const scrollTop1 =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;
    const scrollTop2 = Math.abs(parseInt(document.body.style.top));
    const scrollTop = isNaN(scrollTop2) ? scrollTop1 : scrollTop2;

    if (shouldPreventBodyScroll) {
      document.body.style.width = 'calc(100% - 0px)';
      document.body.style.position = 'fixed';
      document.body.style.top = -scrollTop + 'px';
      document.body.style.overflowY = 'hidden';
      blurElements &&
        blurElements.forEach(item => item.classList.add('blurred'));
    } else {
      if (stopBodyScrollEnabling) {
        return;
      }
      document.body.style.width = '';
      document.body.style.position = 'static';
      document.body.style.top = '';
      document.body.style.overflowY = 'scroll';
      blurElements &&
        blurElements.forEach(item => item.classList.remove('blurred'));
      window.scrollTo(0, scrollTop);
    }
  },
  filePreviewHelper(file) {
    return new Promise(resolve => {
      this.fileReader = new FileReader();

      this.fileReader.onload = e => {
        resolve(e.target.result);
      };
      this.fileReader.readAsDataURL(file);
    });
  },
  copyFile(file) {
    if (!(file instanceof File)) return null;
    return new File([file], file.name, { type: file.type });
  },
  unUnderscore(value) {
    const noUnderscore = value.replace(/_/g, ' ');
    return noUnderscore.charAt(0).toUpperCase() + noUnderscore.slice(1);
  },
  toMillimeter(value) {
    return Math.round(value * 10);
  },
  fromMillimeter(value) {
    return +(value / 10).toFixed(2);
  },
  toGram(value) {
    return Math.round(value * 1000);
  },
  fromGram(value) {
    return +(value / 1000).toFixed(2);
  },
  toCents(value) {
    return Math.round(value * 100);
  },
  fromCents(value) {
    return +(value / 100).toFixed(2);
  },
  /**
   * Validates all forms for a given component which have the specified Keyword in a vue $ref
   * Also expands the language b-collapse elements where necessary
   *
   * @param {$refs} $refs vue refs, object with arrays (when ref key is used several times) or objects (when every  ref is used once)
   * @param {Boolean} expandTranslatable - if true, tries to expand the translatable tabs
   * @param {String} refsKey - by default FormGenerator. Validates only refs where this key is found
   */
  validate($refs, expandTranslatable = true, refsKey = 'FormGenerator') {
    // Mark red all required fields
    const keys = Object.keys($refs).filter(key => key.includes(refsKey));

    const components = [];

    for (const key of keys) {
      if (Array.isArray($refs[key])) {
        for (const component of $refs[key]) {
          components.push(component);
        }
      } else {
        components.push($refs[key]);
      }
    }

    const validationResult = components.reduce((res, component) => {
      if (!component) {
        return true;
      }

      if (expandTranslatable) {
        // Opening all language b-collapsed b-cards inside translatable component
        component.$parent.$parent.showAll = true;
      }
      const currentResult = component.validate();
      return res && currentResult;
    }, true);

    if (!validationResult) {
      components[0].$nextTick(() => this.scollIntoView('.form-group.error'));
    }
    return validationResult;
  },
  mutationMapper(state) {
    const m = {};
    Object.keys(state).forEach(key => {
      m['set' + key.charAt(0).toUpperCase() + key.substr(1)] = (
        state,
        payload
      ) => {
        state[key] = payload;
      };
    });
    return m;
  },
  wrapLanguagesForApollo(schema, languages, languageModels, extraFields = {}) {
    const pack = [];
    const fields = schema.fields.map(item => item.model);

    for (const lang of languages) {
      const draft = {
        id: languageModels[lang.id].id,
        language_id: lang.id,
        ...extraFields,
      };

      let nulls = 0;
      for (const field of fields) {
        let value = languageModels[lang.id][field];
        let valueName = null;

        if (languageModels[lang.id]['name']) {
          valueName = languageModels[lang.id]['name'];
        }

        // generate slug
        if (
          field === 'slug' &&
          valueName &&
          ((value && value == '') || !value)
        ) {
          value = slugify(valueName, { lower: true })?.toLowerCase();
        }

        draft[field] = value;
        if (!value && value !== 0) {
          nulls++;
        }
      }
      if (nulls !== fields.length) {
        pack.push(draft);
      }
    }
    return pack;
  },
  buildAttributeValueLabel(data) {
    const userLanguageId = user.default_language_id;

    let attributeValues = [];
    data.forEach(function(attribute) {
      const attrText =
        attribute.attribute_texts.find(
          at => at.language_id === userLanguageId
        ) || attribute.attribute_texts[0];
      attribute.attribute_values.forEach(function(attribute_value) {
        if (!attribute_value.attribute_value_group_id) {
          return;
        }
        if (userLanguageId && attribute_value.language_id !== userLanguageId) {
          return;
        }
        attributeValues.push({
          id: attribute_value.attribute_value_group_id,
          label: `${attrText.name} > ${attribute_value.value}`,
        });
      });
    });
    return attributeValues;
  },
  buildParentTree(data, textKey, nameKey, withAdditionalDetails) {
    const hashMap = {};
    data.forEach(item => (hashMap[item.id] = item));

    return data.map(item => {
      let parent_id = item.parent && item.parent.id;
      let label = item[textKey][0][nameKey];
      let parentDeepLevel = 5;
      while (parent_id && parentDeepLevel > 0) {
        const currentParent = hashMap[parent_id];

        label = currentParent[textKey][0][nameKey] + ' > ' + label;
        parent_id = currentParent.parent && currentParent.parent.id;

        parentDeepLevel--;
      }

      if (withAdditionalDetails) {
        if (item.total_products_amount) {
          label = `${label} (Products: ${item.total_products_amount})`;
        }

        if (item.total_inspiration_images_amount) {
          label = `${label} (Inspiration Images: ${item.total_inspiration_images_amount})`;
        }
      }

      return {
        id: item.id,
        label,
        slug: item[textKey][0]['slug'],
        seo_title: item[textKey][0]['seo_title'],
        seo_description: item[textKey][0]['seo_description'],
        description: item[textKey][0]['description'],
      };
    });
  },
  isTypeImage(type) {
    // TODO: Can be refined later
    if (type.includes('image')) {
      return true;
    }

    // Needed for File object types like 'image/jpeg'
    const typeParsedRaw = type.split('/');
    const typeParsed = typeParsedRaw[typeParsedRaw.length - 1];
    return ['jpg', 'gif', 'jpeg', 'png', 'svg+xml', 'x-icon'].includes(
      typeParsed
    );
  },
};
