<template>
  <div class="wrapper">
    <div v-for="(item, index) in listItems" class="row-value clear">
      <span>{{ index }}:</span>
      <input :key="getIndex(item)" class="form-control" :id="index === 0 ? getFieldID(schema) : getFullFieldId(schema, item)"
          :type="schema.inputType.toLowerCase()" :value="item[valueKey]" @input="onInput(item, $event, index)" @blur="onBlur(item, index)" :class="schema.fieldClasses" :disabled="disabled" :autocomplete="schema.autocomplete"
          :checked="schema.checked" :dirname="schema.dirname" :height="schema.height" :max="schema.max" :maxlength="schema.maxlength" :min="schema.min" :minlength="schema.minlength" :name="schema.inputName" :placeholder="`${schema.placeholder || ''} ${index + 1}`"
          :readonly="schema.readonly" :required="schema.required" :size="schema.size" :step="schema.step" :width="schema.width" />
    </div>
    <p style="text-align: right">
      <a @click="addItem()" class="btn btn-primary btn-xs" style="color: #fff">Add new item</a>
    </p>
  </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import ListWithVariableLength from '@/components/Forms/mixins/ListWithVariableLength'

export default {
  mixins: [VueFormGenerator.abstractField, ListWithVariableLength],

	methods: {
    syncModel() {
      const copy = [...this.listItems]
      if (copy.length && !copy[copy.length - 1][this.valueKey]) {
        this.value = copy.slice(0, copy.length - 1)
      } else {
        this.value = copy
      }
    },
    onInput(item, $event, index) {
      item[this.valueKey] = $event.target.value
      this.syncModel()
      if ($event.target.value && index === this.listItems.length - 1) {
        this.addItem()
      }
    },
    onBlur(item, index) {
      if (!item[this.valueKey]) {
        this.removeItem(index)
        this.syncModel()
      }
    },
    getFullFieldId(schema, item) {
      return this.getFieldID(schema) + this.getIndex(item)
    },
    addItem() {
      const newItem = {value: ''}
      this.addToListItems(newItem)
    },
    removeItem(index) {
      if (this.listItems.length > 1) {
        this.removeFromListItems(index)
      }
    },
  },
  computed: {
    valueKey() {
      return this.schema.valueKey
    }
  },
	created () {
    if (this.value) {
      this.listItems = [...this.value]
    }
    this.addItem()
  },
  watch: {
    value (newVal, oldVal) {
      if (newVal) {
        this.listItems = [...this.value]
        this.addItem()
      }
    }
  }
};

</script>

<style lang="scss">
	.vue-form-generator .field-simple-text-list {
    .row-value {
      span {
        width: 50px;
        display: inline-block;
        float: left;
        padding-top: 5px;
      }
      input {
        width: 90%;
      }
    }
		.wrapper {
			width: 100%;
		}
    input {
      margin-bottom: 5px;
    }
		input[type="radio"] {
			width: 100%;
		}
		input[type="color"] {
			width: 60px;
		}
		input[type="range"] {
			padding: 0;
		}

		.helper {
			margin: auto 0.5em;
		}
	}
</style>
