import $helpers from '@/utils/helpers';

// initial state
const state = {
  inputCounter: 0,
  moduleItemsIds: [],
};

// actions
const actions = {
  async updateInputCounter({ commit, state }, shouldIncrement) {
    commit('setInputCounter', shouldIncrement ? state.inputCounter + 1 : 0);
  },
  async updateModuleItemsIds({ commit }, payload) {
    commit('setModuleItemsIds', payload);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: $helpers.mutationMapper(state),
};
