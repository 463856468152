<template>
  <th v-if="isHeader">
    {{ title }}
  </th>
  <td v-else>
    <div v-if="isNaN(rowData.status)" class="p-1 bg-success rounded text-center font-weight-bold">{{ rowData.status }}</div>
    <b-progress v-else :value="rowData.status" :max="100" show-progress animated></b-progress>
  </td>
</template>

<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'

export default {
  mixins: [VuetableFieldMixin]
};
</script>
