export default {
  data() {
    return {
      listItems: [],
      newlyAddedKeyStorage: new WeakMap()
    }
  },
  methods: {
    getIndex(item) {
      return item.id ? item.id : this.newlyAddedKeyStorage.get(item)
    },
    addToListItems(newItem) {
      this.newlyAddedKeyStorage.set(
        newItem,
        Math.random()
          .toString()
          .slice(2, 12) + Date.now()
      )
      if (this.listItems) {
        this.listItems.push(newItem)
      } else {
        this.listItems = [newItem]
      }
    },
    removeFromListItems(index) {
      this.listItems.splice(index, 1)
    }
  }
}
