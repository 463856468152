export default {
  bootstrap4: {
    table: {
      tableWrapper: "",
      tableHeaderClass: "mb-0",
      tableBodyClass: "mb-0",
      tableClass: "table table-bordered table-hover table-striped",
      loadingClass: "loading",
      ascendingIcon: "fa fa-arrow-up",
      descendingIcon: "fa fa-arrow-down",
      ascendingClass: "sorted-asc",
      descendingClass: "sorted-desc",
      sortableIcon: "fa fa-sort",
      detailRowClass: "vuetable-detail-row",
      handleIcon: "fa fa-bars text-secondary",
      renderIcon(classes) {
        return `<i class="${classes.join(" ")}"></span>`
      }
    },
    pagination: {
      wrapperClass: "vuetable-pagination pull-right pagination float-right",
      activeClass: "active btn-primary",
      disabledClass: "disabled",
      pageClass: "btn btn-border page-item",
      linkClass: "btn btn-border page-link",
      paginationClass: "pagination",
      paginationInfoClass: "float-left",
      dropdownClass: "form-control",
      icons: {
        first: "fa fa-angle-double-left",
        prev: "fa fa-angle-left",
        next: "fa fa-angle-right",
        last: "fa fa-angle-double-right"
      },
      // From other tutorial
      infoClass: "pull-left"
    }
  }
}
