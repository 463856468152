<template>
  <div
    class="d-flex justify-content-end align-items-center flex-row"
    :class="{ 'flex-wrap': extraActions }"
  >
    <router-link
      :to="defaultViewRouterLinkTo(rowData)"
      v-if="onViewItem"
      class="btn btn-warning btn-sm"
    >
      <i class="fa fa-eye"></i>
    </router-link>

    <router-link
      :to="defaultEditRouterLinkTo(rowData)"
      v-if="onEditItem"
      class="btn btn-warning btn-sm"
    >
      <i class="fa fa-pencil"></i>
    </router-link>

    <button
      v-if="onDeleteItem"
      class="btn btn-danger btn-sm"
      @click="onDeleteItem(rowData)"
    >
      <i class="fa fa-trash"></i>
    </button>

    <b-form-select
      v-if="extraActions && typeof extraActions === 'object'"
      style="width: 100px"
      class="ml-2 mt-2 d-flex"
      :plain="true"
      :options="getOptions(rowData)"
      v-model="selectedOption"
      @input="onExtraAcion"
    >
    </b-form-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: null,
      actionMapping: {}
    };
  },
  props: [
    "rowData",
    "onViewItem",
    "onEditItem",
    "onDeleteItem",
    "extraActions",
    "defaultViewRouterLinkTo",
    "defaultEditRouterLinkTo",
    "onEditItemRoute"
  ],
  methods: {
    getOptions(rowData) {
      const options = [{ value: null, text: "More" }];
      Object.keys(this.extraActions).forEach(key => {
        if (typeof this.extraActions[key] === "function") {
          this.actionMapping[key] = this.extraActions[key](rowData);
          options.push({ value: key, text: key });
        }
      });

      return options;
    },
    async onExtraAcion(key) {
      this.actionMapping[key] && (await this.actionMapping[key]());
      this.$nextTick(() => {
        this.selectedOption = null;
        this.$parent &&
          this.$parent.$parent &&
          this.$parent.$parent.refreshGrid &&
          this.$parent.$parent.refreshGrid(true);
      });
    }
  }
};
</script>
