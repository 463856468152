import { ApolloClient } from "apollo-client"
import { createUploadLink } from "apollo-upload-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

const headers = {
  'apollographql-client-name': `wonder-backoffice-${process.env.NODE_ENV}`,
  'apollographql-client-version': process.env.NODE_ENV
}

const httpLink = createUploadLink({
  uri: process.env.VUE_APP_APOLLO_URL,
  credentials: 'include',
  headers
})

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_APOLLO_WS_URL,
  options: {
    reconnect: true
  }
});

export default new ApolloClient({
  link: split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  ),
  cache: new InMemoryCache()
})
