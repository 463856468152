<template>
  <!-- FullscreenWaitingSpinner -->
  <div class="fullscreen-waiting-spinner">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <p v-if="retryEnabled">
        <button class="btn btn-primary btn-sm" v-on:click="closeFullscreenWaitingSpinner()">Retry</button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['blurSelectors', 'retryEnabled'],
  mounted() {
    this.$helpers.handleBodyOverflow(true, false, this.blurSelectors)
  },
  beforeDestroy() {
    this.$helpers.handleBodyOverflow(false, false, this.blurSelectors)
  },
  methods: {
    closeFullscreenWaitingSpinner() {
      this.$parent.loading = false;
      this.$helpers.handleBodyOverflow(false, false, this.blurSelectors)
    }
  }
}
</script>

<style lang="scss">
.fullscreen-waiting-spinner {
  .btn {
    margin-top: 15px;
    z-index: 10000;
  }
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: none;
  .spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
    div {
      width: 18px;
      height: 18px;
      background-color: #ff530d;
      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      &.bounce1 {
        animation-delay: -0.32s;
      }
      &.bounce2 {
        animation-delay: -0.16s;
      }
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1.0);
    }
  }
}
</style>

