<template>
  <div class="wrapper">
    <trumbowyg v-model="value" :config="config"></trumbowyg>
  </div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator';

export default {
  mixins: [VueFormGenerator.abstractField],
  data() {
    return {
      config: {
        autogrow: true,
        tagsToRemove: ['html', 'marquee', 'img'],
        removeformatPasted: true,
        tagsToKeep: [
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'p',
          'br',
          'b',
          'strong',
          'i',
          'u',
          'del',
          'sub',
          'sup',
          'ul',
          'li',
          'ol',
          'hr',
          'a',
          'link',
          'embed',
          'iframe',
          'script',
        ],
        btns: [
          ['viewHTML'],
          ['undo', 'redo'],
          ['customFormatting'],
          ['strong', 'em', 'del', 'underline'],
          ['superscript', 'subscript'],
          ['link'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
          ['fullscreen'],
        ],
        btnsDef: {
          customFormatting: {
            dropdown: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
            ico: 'p',
          },
        },
      },
    };
  },
};
</script>
