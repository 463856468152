var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('v-select',{ref:"vSelect",attrs:{"loading":_vm.asyncQueryActive,"onSearch":_vm.onSearch,"inputId":_vm.getFieldID(_vm.schema),"multiple":_vm.schema.multiple,"value":_vm.context,"onChange":_vm.onChange,"disabled":_vm.schema.readonly || _vm.schema.disabled,"options":_vm.options,"placeholder":_vm.schema.placeholder,"filterable":false},scopedSlots:_vm._u([{key:"selected-option-container",fn:function({ option, deselect }){return _c('div',{staticClass:"d-flex w-100 justify-content-between align-items-center select-item"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center pl-2"},[(option.default_image)?_c('img',{staticClass:"product-image mr-2",attrs:{"src":option.default_image.image
              ? option.default_image.image.file_url
              : '/img/no-image.png'}}):_vm._e(),_c('div',{staticClass:"pl-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"m-0"},[_c('strong',[_vm._v(_vm._s(option.label))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[(option.product_number)?_c('p',{staticClass:"m-0"},[_vm._v("Product Number: "+_vm._s(option.product_number))]):_vm._e(),(option.supplier_product_number)?_c('p',{staticClass:"m-0"},[_vm._v("Supplier Product Number: "+_vm._s(option.supplier_product_number))]):_vm._e()]),_c('div',{staticClass:"col-6"},[(option.available)?_c('p',{staticClass:"m-0"},[_vm._v("Available: "+_vm._s(option.available))]):_vm._e(),(option.sellable_stock)?_c('p',{staticClass:"m-0"},[_vm._v("Sellable Stock: "+_vm._s(option.sellable_stock))]):_vm._e(),(!option.sellable_stock)?_c('p',{staticClass:"m-0"},[_vm._v("Sellable Stock: 0")]):_vm._e(),(option.available_stock)?_c('p',{staticClass:"m-0"},[_vm._v("Available Stock: "+_vm._s(option.available_stock))]):_vm._e(),(option.available_stock_manufacturer)?_c('p',{staticClass:"m-0"},[_vm._v(" Available Stock Manufacturer: "+_vm._s(option.available_stock_manufacturer)+" ")]):_vm._e()])])])]),_c('button',{staticClass:"btn btn-default btn-trash text-danger",on:{"click":function($event){$event.preventDefault();return deselect(option)}}},[_c('i',{staticClass:"fa fa-trash",attrs:{"aria-hidden":"true"}})])])}},{key:"option",fn:function({
        label,
        default_image,
        product_number,
        supplier_product_number,
        available,
        available_stock,
        available_stock_manufacturer,
        sellable_stock,
        delivery_days,
      }){return _c('div',{staticClass:"d-flex justify-content-start align-items-center p-0"},[(default_image)?_c('img',{staticClass:"product-image",attrs:{"src":default_image.image
            ? default_image.image.file_url
            : '/img/no-image.png'}}):_vm._e(),_c('div',{staticClass:"pl-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"m-0"},[_c('strong',[_vm._v(_vm._s(label))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(product_number)?_c('p',{staticClass:"m-0"},[_vm._v("Product Number: "+_vm._s(product_number))]):_vm._e(),(supplier_product_number)?_c('p',{staticClass:"m-0"},[_vm._v("Supplier Product Number: "+_vm._s(supplier_product_number))]):_vm._e(),_c('p',{staticClass:"m-0 h4 pb-2 font-weight-bold"},[_c('i',{staticClass:"fa fa-truck"}),_vm._v("   "),(sellable_stock >= 1 && (available_stock_manufacturer === 0 || available_stock_manufacturer >= 1))?_c('span',{staticClass:"text-success"},[_vm._v("24h")]):(delivery_days)?_c('span',[_vm._v(_vm._s(delivery_days + ' days'))]):_c('span',{staticClass:"text-danger"},[_vm._v("???")])])]),_c('div',{staticClass:"col"},[_c('p',{staticClass:"m-0"},[_vm._v(" ")]),(available)?_c('p',{staticClass:"m-0"},[_vm._v("Available: "+_vm._s(available))]):_vm._e(),(sellable_stock)?_c('p',{staticClass:"m-0"},[_vm._v("Sellable Stock: "+_vm._s(sellable_stock))]):_vm._e(),(!sellable_stock)?_c('p',{staticClass:"m-0"},[_vm._v("Sellable Stock: 0")]):_vm._e(),(available_stock)?_c('p',{staticClass:"m-0"},[_vm._v("Available Stock: "+_vm._s(available_stock))]):_vm._e(),(available_stock_manufacturer)?_c('p',{staticClass:"m-0"},[_vm._v("Available Stock Manufacturer: "+_vm._s(available_stock_manufacturer))]):_vm._e()])])])])}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }