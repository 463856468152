import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import { createModule } from 'vuex-toast';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import 'vuex-toast/dist/vuex-toast.css';
import $helpers from '@/utils/helpers';

import GetAllLanguages from '@/apollo/languages/queries/GetAllLanguages.graphql';
import GetAllChannels from '@/apollo/channels/queries/GetAllChannels.graphql';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const state = {
  isAuthenticated: false,
  languages: [],
  channels: [],
  fullScreenPreview: null,
  currentChannel: 1,
};

export default new Vuex.Store({
  modules: {
    common,
    toast: createModule({
      dismissInterval: 8000,
    }),
  },
  strict: debug,
  state,
  mutations: $helpers.mutationMapper(state),
  actions: {
    async loadChannels({ commit }) {
      const channels = await Vue.$apolloHelper({
        fallbackData: [],
        query: GetAllChannels,
        force: true,
      });
      commit('setChannels', channels);
    },
    async loadLanguages({ commit }) {
      const languages = await Vue.$apolloHelper({
        fallbackData: [],
        query: GetAllLanguages,
        force: true,
      });
      commit('setLanguages', languages);
    },
    setCurrentChannel({ commit }, payload) {
      commit('setCurrentChannel', payload);
    },
    setFullScreenPreview({ commit }, payload) {
      commit('setFullScreenPreview', payload);
    },
    setAuthState({ commit }, payload) {
      commit('setIsAuthenticated', payload);
    },
    informUserSuccess({ dispatch }, text) {
      dispatch(ADD_TOAST_MESSAGE, {
        text,
        type: 'success',
        dismissAfter: 10000,
      });
    },
    informUserError({ dispatch }, text) {
      dispatch(ADD_TOAST_MESSAGE, {
        text,
        type: 'danger',
        dismissAfter: 10000,
      });
    },
    informUserInfo({ dispatch }, text) {
      dispatch(ADD_TOAST_MESSAGE, {
        text,
        type: 'info',
        dismissAfter: 10000,
      });
    },
  },
});
