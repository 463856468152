import VueFormGenerator from 'vue-form-generator/dist/vfg-core.js';
import 'vue-form-generator/dist/vfg-core.css'; // optional core css additions

import FieldFileUpload from '@/components/Forms/FieldFileUpload';
import FieldVueSelect from '@/components/Forms/FieldVueSelect';
import FieldProductSelect from '@/components/Forms/FieldProductSelect';
import MultiSelectPremium from '@/components/Forms/MultiSelectPremium';
import SimpleTextList from '@/components/Forms/SimpleTextList';
import ProductAttributeList from '@/components/Forms/ProductAttributeList';
import FieldSwitch from '@/components/Forms/FieldSwitch';
import WysiwygField from '@/components/Forms/WysiwygField';
import CodeEditorField from '@/components/Forms/CodeEditorField';
import ProgressField from '@/views/Import/ProgressField';
import GenericFormLayout from '@/components/Forms/GenericFormLayout';
import CustomFieldArray from '@/components/Forms/CustomFieldArray';
import CustomFieldObject from '@/components/Forms/CustomFieldObject';
import vSelect from '@/components/Forms/VueSelect';
import Log from '@/components/common/Debug/Log';
import { FieldSourcecode } from 'vfg-field-sourcecode';
// import { FieldArray } from 'vfg-field-array'
// import { FieldObject } from 'vfg-field-object'

export default {
  install(Vue) {
    Vue.component('v-select', vSelect);
    Vue.component('field-file-upload', FieldFileUpload);
    Vue.component('field-vue-select', FieldVueSelect);
    Vue.component('field-product-select', FieldProductSelect);
    Vue.component('field-select-premium', MultiSelectPremium);
    Vue.component('field-simple-text-list', SimpleTextList);
    Vue.component('field-product-attribute-list', ProductAttributeList);
    Vue.component('field-switch', FieldSwitch);
    Vue.component('field-wysiwyg', WysiwygField);
    Vue.component('field-code-editor', CodeEditorField);
    Vue.component('field-progress', ProgressField);
    Vue.component('field-array', CustomFieldArray);
    Vue.component('field-object', CustomFieldObject);
    Vue.component('field-sourcecode', FieldSourcecode);
    Vue.component('generic-form-layout', GenericFormLayout);
    Vue.component('Log', Log);
    Vue.use(VueFormGenerator);

    VueFormGenerator.validators.object = (value, field) => {
      if (field.required && !value) {
        return [VueFormGenerator.validators.resources.fieldIsRequired];
      } else {
        return [];
      }
    };

    VueFormGenerator.validators.arrayOfObjects = (value, field) => {
      if (
        field.required &&
        (!value ||
          value.length === 0 ||
          (field.valueKey && value.length === 1 && !value[0][field.valueKey]))
      ) {
        return [VueFormGenerator.validators.resources.fieldIsRequired];
      } else {
        return [];
      }
    };

    // The function name (integerArray) is used in code to check the data type of model
    VueFormGenerator.validators.integerArray = function integerArray(
      value,
      field
    ) {
      if (field.required && (!value || value.length === 0)) {
        return [VueFormGenerator.validators.resources.fieldIsRequired];
      } else if (
        value &&
        (!Array.isArray(value) ||
          value.reduce((result, current) => result || isNaN(current), false))
      ) {
        return [VueFormGenerator.validators.resources.invalidNumber];
      } else {
        return [];
      }
    };
    // The function name (stringArray) is used in code to check the data type of model
    VueFormGenerator.validators.stringArray = function stringArray(
      value,
      field
    ) {
      if (field.required && (!value || value.length === 0)) {
        return [VueFormGenerator.validators.resources.fieldIsRequired];
      } else if (
        value &&
        (!Array.isArray(value) ||
          value.filter(value => value && typeof value === 'string').length !==
            value.length)
      ) {
        return [VueFormGenerator.validators.resources.invalidNumber];
      } else {
        return [];
      }
    };
    VueFormGenerator.validators.numberFixed = function numberFixed(
      value,
      field
    ) {
      if (field.required && !value && value !== 0) {
        return [VueFormGenerator.validators.resources.fieldIsRequired];
      } else if ((value || value === 0) && typeof value !== 'number') {
        return [VueFormGenerator.validators.resources.invalidNumber];
      } else if (
        (value || value === 0) &&
        field.min !== undefined &&
        field.min > value
      ) {
        return [
          VueFormGenerator.validators.resources.numberTooSmall.replace(
            '{0}',
            field.min
          ),
        ];
      } else if (
        (value || value === 0) &&
        field.max !== undefined &&
        field.max < value
      ) {
        return [
          VueFormGenerator.validators.resources.numberTooBig.replace(
            '{0}',
            field.max
          ),
        ];
      } else {
        return [];
      }
    };
  },
};
