export default apollo => {
  return async ({
    fallbackData = {},
    query,
    mutation,
    subscription,
    variables,
    force,
    uri,
  }) => {
    let result;
    let requestType;

    try {
      const payload = {
        variables,
      };

      if (query) {
        payload.query = query;
        requestType = 'query';
      }

      if (mutation) {
        payload.mutation = mutation;
        requestType = 'mutate';
      }

      if (subscription) {
        payload.query = subscription;
        requestType = 'subscribe';
      }

      if (force) {
        payload.fetchPolicy = 'no-cache'; // This fetch policy will never return your initial data from the cache. Instead it will always make a request using your network interface to the server. Unlike the network-only policy, it also will not write any data to the cache after the query completes.
      }

      if (uri) {
        payload.context = {
          uri,
        };
      }

      if (!payload.context) {
        payload.context = {
          headers: {
            'apollographql-client-name': `wonder-backoffice-${process.env.NODE_ENV}`,
            'apollographql-client-version': process.env.NODE_ENV,
          },
        };
      }

      const apolloQuery = apollo[requestType](payload);

      if (subscription) return apolloQuery;

      const apolloResponse = await apolloQuery;

      const requestName = Object.keys(apolloResponse.data)[0];

      result = apolloResponse.data[requestName];
      if (result === null) {
        result = fallbackData;
        if (process.env.NODE_ENV !== 'production') {
          console.log('Using fallback data for the request: ' + requestName);
        }
      }
    } catch (err) {
      result = fallbackData;
      result.errors = err.graphQLErrors;
      const type = query || mutation;
      if (type && process.env.NODE_ENV !== 'production')
        console.log(
          Object.keys(type)[3],
          '->',
          process.client ? err : err.message
        );
    }
    return result;
  };
};
