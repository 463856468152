<template>
  <div class="wrapper">
    <v-select
      ref="vSelect"
      :loading="asyncQueryActive"
      :onSearch="onSearch"
      :inputId="getFieldID(schema)"
      :multiple="schema.multiple"
      :value="context"
      :onChange="onChange"
      :disabled="schema.readonly || schema.disabled"
      :options="options"
      :placeholder="schema.placeholder"
    >
      <div
        class="d-flex w-100 justify-content-between align-items-center"
        slot="selected-option-container"
        slot-scope="{ option, deselect }"
      >
        <div class="d-flex justify-content-start align-items-center pl-2">
          <img
            v-if="option.default_image"
            :src="
              option.default_image.image
                ? option.default_image.image.file_url
                : '/img/no-image.png'
            "
            class="product-image"
          />
          <img
            v-if="option.breadcrumb_image"
            :src="
              option.breadcrumb_image.file_url
                ? option.breadcrumb_image.file_url
                : '/img/no-image.png'
            "
            class="product-image"
          />
          <div class="pl-2">
            <p class="m-0">
              <strong>{{ option.label }}</strong>
            </p>
            <p class="m-0" v-if="option.product_number">
              Product Number: {{ option.product_number }}
            </p>
            <p class="m-0" v-if="option.supplier_product_number">
              Supplier Product Number: {{ option.supplier_product_number }}
            </p>
            <p class="m-0" v-if="option.price_gross">
              Price: {{ option.price_gross / 100 }}
            </p>
          </div>
        </div>
        <button class="btn btn-default" v-if="!schema.hideAction" @click.prevent="deselect(option)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>

      <dir
        class="d-flex justify-content-start align-items-center p-0"
        slot="option"
        slot-scope="{
          label,
          default_image,
          breadcrumb_image,
          product_number,
          supplier_product_number,
          ean,
          product_texts,
          price_gross,
        }"
      >
        <img
          v-if="default_image"
          :src="
            default_image.image
              ? default_image.image.file_url
              : '/img/no-image.png'
          "
          class="product-image"
        />
        <img
          v-if="breadcrumb_image"
          :src="
            breadcrumb_image.file_url
              ? breadcrumb_image.file_url
              : '/img/no-image.png'
          "
          class="product-image"
        />
        <div class="pl-2">
          <p class="m-0">
            <strong>{{ label }}</strong>
          </p>
          <p class="m-0" v-if="product_number">
            Product Number: {{ product_number }}
          </p>
          <p class="m-0" v-if="supplier_product_number">
            Supplier Product Number: {{ supplier_product_number }}
          </p>
          <p class="m-0" v-if="price_gross">Price: {{ price_gross / 100 }}</p>
        </div>
      </dir>
    </v-select>
  </div>
</template>

<script>
// https://sagalbot.github.io/vue-select/docs/Api/Props.html
import VueFormGenerator from 'vue-form-generator';

export default {
  mixins: [VueFormGenerator.abstractField],
  data() {
    return {
      options: [],
      context: null,
      asyncQueryActive: false,
      modelKeys: null,
      channelId: null,
    };
  },
  methods: {
    onSearch(searchValue) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        this.doSearch(searchValue);
      }, 500);
    },
    async doSearch(keyword) {
      this.asyncQueryActive = true;

      const responses = {};

      if (!this.modelKeys) {
        return;
      }

      for (const modelKey of this.modelKeys) {
        const serverResponse = await this.$apolloHelper({
          fallbackData: [],
          query: Array.isArray(this.schema.payload[modelKey].query)
            ? this.schema.payload[modelKey].query[0]
            : this.schema.payload[modelKey].query,
          force: true,
          variables:
            modelKey === 'product'
              ? {
                  page: 1,
                  language_id: 1,
                  filters: {
                    keywords: [keyword],
                    channels: [{ id: this.channelId }],
                  },
                }
              : {
                  page: 1,
                  keyword,
                  channel_id: this.channelId,
                },
        });

        const arr = Array.isArray(this.schema.payload[modelKey].query)
          ? serverResponse[this.schema.payload[modelKey].query[1]]
          : serverResponse;

        responses[modelKey] = arr;
      }

      const options = this.mapForVueSelect(responses);

      this.options = options;
      this.asyncQueryActive = false;
    },
    mapForVueSelect(responses, existingValuesObject) {
      const arr = [];

      if (!this.modelKeys) {
        return;
      }

      for (const modelKey of this.modelKeys) {
        if (!responses[modelKey] || !responses[modelKey].length) {
          continue;
        }
        // This variable is used to copy the existing state of each item on the inner list items state of vue-select component
        // Generally, we need to keep the stateId field
        const existingValuesMap = {};

        if (
          existingValuesObject &&
          existingValuesObject[modelKey] &&
          existingValuesObject[modelKey].length
        ) {
          for (const existingValue of existingValuesObject[modelKey]) {
            existingValuesMap[existingValue.id] = existingValue;
          }
        }
        const key = Object.keys(responses[modelKey][0]).find(
          item => item.indexOf('_texts') !== -1
        );
        responses[modelKey].forEach(item => {
          let label =
            item[key] && item[key].length ? item[key][0].name : 'No value';

          if (item['__typename'] == 'Category' || item['category_texts']) {
            if (item['parent'] && item['parent'] != '') {
              label =
                item[key] && item[key].length
                  ? item['parent'] && item['parent']['category_texts']
                    ? item['parent']['category_texts'][0]
                      ? `${item['parent']['category_texts'][0]['name']} > ${
                          item[key][0].name
                        }`
                      : item[key][0].name
                    : ''
                  : 'No value';
            } else {
              label = item['category_texts'][0]['name'];
            }
          } else {
            label = `${label} (${item.product_number}) (${
              item.supplier_product_number
            })`;
          }

          const draft = {
            ...item,
            [modelKey]: true,
            label: label,
          };

          // Here we add the existing state to the new list items data models
          arr.push(
            existingValuesMap[item.id]
              ? { ...draft, ...existingValuesMap[item.id] }
              : draft
          );
        });
      }

      return arr;
    },
    onChange(newValue) {
      let result = { isVueSelect: true };
      const asArray = this.schema.multiple ? newValue : [newValue];

      if (!this.modelKeys) {
        return;
      }

      asArray &&
        asArray.forEach(item => {
          for (const modelKey of this.modelKeys) {
            if (item && item[modelKey]) {
              if (result[modelKey]) {
                result[modelKey].push(item);
              } else {
                result[modelKey] = [item];
              }
            }
          }
        });
      result = result === undefined ? null : result;
      this.value = result;
    },
    async setContext() {
      if (this.$route && this.$route.query && this.$route.query.channel_id) {
        this.channelId = parseInt(this.$route.query.channel_id);
      }

      this.modelKeys = Object.keys(this.schema.payload);
      if (!this.value) {
        this.context = [];
        return;
      }

      // Avoiding infinite loop for multiple because of non-prmimitive data type
      if (this.value.isVueSelect) {
        return;
      }
      if (!this.modelKeys) {
        return;
      }

      let serverResponse = {};

      for (const modelKey of this.modelKeys) {
        if (!Array.isArray(this.value[modelKey])) {
          continue;
        }
        serverResponse[modelKey] = await this.$apolloHelper({
          fallbackData: [],
          query: this.schema.payload[modelKey].prefetch,
          force: true,
          variables: { ids: this.value[modelKey].map(item => item.id) },
        });
      }

      const result = this.mapForVueSelect(serverResponse, this.value);

      // Setting the right order of Module Items
      if (
        this.isModuleItemsSelect &&
        this.originalSortOrderArray &&
        this.originalSortOrderArray.length
      ) {
        const incomingValues = this.originalSortOrderArray;
        result.sort((a, b) => {
          return (
            incomingValues.indexOf(a.stateId) -
            incomingValues.indexOf(b.stateId)
          );
        });
      }

      this.context = this.schema.multiple
        ? result
        : result.length
        ? result[0]
        : null;
    },
  },
  computed: {
    isModuleItemsSelect() {
      return this.schema.model && ['module_items'].includes(this.schema.model);
    },
    originalSortOrderArray() {
      return this.$store.state.common.moduleItemsIds;
    },
  },
  /*   mounted() {
    // For development: to hold the select dropdown unclosed
    // https://github.com/sagalbot/vue-select/blob/master/src/components/Select.vue
    this.$refs.vSelect._computedWatchers.dropdownOpen.getter = () => true
  }, */
  watch: {
    value: {
      handler: 'setContext',
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.field-select-premium {
  .disabled {
    .btn-trash {
      display: none;
    }
    input[type='search'] {
      display: none;
    }
    .select-item {
      border-bottom: 0;
    }
  }

  .select-item {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #e4e4e4;
    &:hover {
      background-color: #f0f3f5;
    }
    .btn-trash {
      font-size: 1.2rem;
    }
  }

  .v-select input[type='search'].form-control {
    border: 1px solid rgba(60, 60, 60, 0.26);
  }

  .selected-options-wrapper-container {
    width: 100%;
    max-height: 250px;
    overflow: auto;
  }

  .product-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
}
</style>
