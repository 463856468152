<template>
  <div>
    <b-form
      class="standard-edit-create-form"
      :class="{ 'view-mode-form': pageMode === pageModes.READ }"
    >
      <b-row>
        <b-col sm="12">
          <b-card v-if="!(options && options.hideHeader)">
            <div
              slot="header"
              class="d-flex justify-content-between align-items-center"
            >
              <strong>{{ pageTitle }}</strong>
              <slot name="header"></slot>
            </div>
            <slot name="first"></slot>
          </b-card>
          <slot></slot>
        </b-col>
      </b-row>
    </b-form>
    <div
      class="standard-edit-create-form__action-button mb-3"
      style="position: sticky; bottom: 10px; z-index: 100;"
    >
      <b-button
        @click="
          pageMode !== pageModes.READ && !(options && options.forceGoBack)
            ? createOrEdit()
            : goBack()
        "
        size="lg"
        :variant="'success'"
        class="btn-block"
        :disabled="disableSubmitWithNotice.length > 0"
        :title="disableSubmitWithNotice"
        ><i
          :class="
            pageMode !== pageModes.READ && !(options && options.forceGoBack)
              ? 'fa fa-save'
              : 'fa fa-eye-slash'
          "
        ></i>
        {{ submitButtonName }}
      </b-button>
    </div>
    <FullscreenWaitingSpinner
      v-if="loading"
      :retryEnabled="true"
      :blurSelectors="[
        '.standard-edit-create-form',
        '.standard-edit-create-form__action-button',
        '.app-footer',
        '.ps-container',
      ]"
    />
  </div>
</template>

<script>
import pluralize from 'pluralize';
import FullscreenWaitingSpinner from '@/components/spinner/FullscreenWaitingSpinner';

export default {
  components: {
    FullscreenWaitingSpinner,
  },
  props: {
    createOrEditHandler: {
      default: null,
    },
    pageName: {
      required: true,
    },
    name: {
      default: '',
    },
    afterSaveRoute: {
      default: null,
    },
    disableSubmitWithNotice: {
      default: '',
    },
    validators: {
      default: null,
    },
    options: {
      default: null,
    },
    /*
      AVAILABLE OPTIONS:

      hideHeader - hide the standard header (Bool)
      forceGoBack - force the "Submit" button look and act like a "Back" button (Bool)
      queryOverride - set a specific "Get" query instead of a generated one (String)
      queryOverrideParams - set pameters for the specific query, set by the "queryOverride" option (Obj)
      noUpdate - when set to "true", makes an update mutation unrequired (Bool)
      submitButtonName - set a specific button name instead of a standard one (String)
      reloadAfterUpdate - force page reload after an update mutation's done (Bool)
      goToEditImmediately - force router to go to edit page streight after create mutation was successful (Bool)
      noChannelOnEdit - don't include channel_id to the Edit route's query (Bool) - to be used with goToEditImmediately
    */
  },
  data() {
    const pluralizedRaw = pluralize(this.pageName).replace(/\s/g, '');
    const pluralized =
      pluralizedRaw.charAt(0).toLowerCase() + pluralizedRaw.slice(1);
    const pageNameFinal = this.pageName.replace(/\s/g, '');

    return {
      pageModes: {
        CREATE: 0,
        READ: 1,
        UPDATE: 2,
      },
      query:
        this.options && this.options.queryOverride
          ? require(`@/apollo/${pluralized}/queries/${this.options.queryOverride}.graphql`)
          : require(`@/apollo/${pluralized}/queries/Get${pageNameFinal}.graphql`),
      createMutation: !this.$route.path.includes('/view')
        ? require(`@/apollo/${pluralized}/mutations/Create${pageNameFinal}.graphql`)
        : null,
      updateMutation:
        !this.$route.path.includes('/view') &&
        !(this.options && this.options.noUpdate)
          ? require(`@/apollo/${pluralized}/mutations/Update${pageNameFinal}.graphql`)
          : null,
      loading: false,
    };
  },
  computed: {
    submitButtonName() {
      if (this.options && this.options.submitButtonName) {
        return this.options.submitButtonName;
      }

      return this.pageMode === this.pageModes.UPDATE &&
        !(this.options && this.options.forceGoBack)
        ? 'Update'
        : this.pageMode === this.pageModes.READ ||
          (this.options && this.options.forceGoBack)
        ? 'Back'
        : 'Create';
    },
    pageNamePlural() {
      return pluralize(this.pageName);
    },
    pageTitle() {
      switch (this.pageMode) {
        case this.pageModes.CREATE:
          return `New ${this.pageName}`;
        case this.pageModes.READ:
          return this.name ? this.name : `View ${this.pageName}`;
        case this.pageModes.UPDATE:
          return `Update ${this.pageName} ${this.name}`;
        default:
          return '';
      }
    },
    pageMode() {
      if (
        (!this.$route.params || !this.$route.params.id) &&
        !this.$route.query.mode
      ) {
        return this.pageModes.CREATE;
      }

      if (
        this.$route.path.includes('edit') ||
        this.$route.query.mode === 'edit' ||
        this.$route.path.includes('random') ||
        this.$route.query.mode === 'random'
      ) {
        return this.pageModes.UPDATE;
      } else if (
        this.$route.path.includes('view') ||
        this.$route.query.mode === 'view'
      ) {
        return this.pageModes.READ;
      } else {
        return -1;
      }
    },
    isReadMode() {
      return this.pageMode === this.pageModes.READ;
    },
    isUpdateMode() {
      return this.pageMode === this.pageModes.UPDATE;
    },
  },
  methods: {
    handleError(msg = '') {
      let displayMessage = msg
        ? `Could not ${this.isUpdateMode ? 'update' : 'create'} ${
            this.pageName
          } (${msg})`
        : 'Ooops! Something went wrong. Please check your connection and try again';

      this.$store.dispatch('informUserError', displayMessage);
    },
    async createOrEdit() {
      if (this.createOrEditHandler) {
        return this.createOrEditHandler();
      }
      const validationResult = this.validate();

      if (!validationResult) {
        this.$store.dispatch(
          'informUserInfo',
          'Please fill in the required fields'
        );
        return;
      }

      const variables = {
        input: this.$parent.mapping
          ? typeof this.$parent.mapping === 'function'
            ? this.$parent.mapping()
            : this.$parent.mapping
          : this.$parent.model,
      };

      this.loading = true;

      const result = await this.$apolloHelper({
        mutation: this.isUpdateMode ? this.updateMutation : this.createMutation,
        fallbackData: {},
        variables,
      });

      this.loading = false;

      if (result && result.errors && result.errors.length) {
        return this.handleError(
          result.errors.map(error => error.message).join(', ')
        );
      } else if (!result || (!result.id && !result.length)) {
        return this.handleError();
      }

      // Needed for correct work of BeforeUnloadable mixin
      this.$store.dispatch('common/updateInputCounter', false);

      if (this.isUpdateMode) {
        this.$emit(
          'modelChange',
          this.$helpers.deepClone(result, { omitTypename: true })
        );

        if (this.options && this.options.reloadAfterUpdate) {
          return this.$router.go();
        }
      } else if (
        !this.isUpdateMode &&
        this.options &&
        this.options.goToEditImmediately
      ) {
        this.$router.push({
          name: `Edit ${this.pageName}`,
          params: { id: result.id },
          query: !this.options.noChannelOnEdit &&
            result.channel &&
            result.channel.id && {
              channel_id: result.channel.id,
            },
        });
      } else {
        this.$router.push(
          this.afterSaveRoute
            ? this.afterSaveRoute
            : { name: `All ${this.pageNamePlural}` }
        );
      }

      this.$store.dispatch('informUserSuccess', 'Successfully saved');
    },
    goBack() {
      this.$router.go(-1);
    },
    validate() {
      if (this.validators && this.validators.length >= 1) {
        for (const validator of this.validators) {
          if (
            validator === 'product_images_not_empty' &&
            (this.$parent.$refs.mainFormGenerator?.model?.product_type ===
              'default' ||
              this.$parent.$refs.mainFormGenerator?.model?.product_type ===
                'outlet')
          ) {
            if (
              this.$parent.$refs.mainFormGenerator?.model?.product_images &&
              this.$parent.$refs.mainFormGenerator?.model?.product_images
                .length === 0
            ) {
              this.$store.dispatch(
                'informUserError',
                'Please provide at least one product image!'
              );

              return false;
            }
          }

          if (
            validator === 'module_tabs_not_empty' &&
            this.$parent.$refs.moduleTabsFormGenerator
          ) {
            if (
              (this.$parent.$refs.moduleTabsFormGenerator?.model?.module_tabs &&
                this.$parent.$refs.moduleTabsFormGenerator?.model?.module_tabs
                  .length === 0) ||
              !this.$parent.$refs.moduleTabsFormGenerator?.model?.module_tabs
            ) {
              this.$store.dispatch(
                'informUserError',
                'Please provide at least one module tab!'
              );

              return false;
            }
          }

          if (
            validator === 'module_images_not_empty' &&
            this.$parent.$refs.imagesComposer
          ) {
            if (
              (this.$parent.$refs.imagesComposer?.model?.module_images &&
                !this.$parent.$refs.imagesComposer?.model?.module_images
                  .length) ||
              !this.$parent.$refs.imagesComposer?.model?.module_images
            ) {
              this.$store.dispatch(
                'informUserError',
                'Please provide at least one MODULE IMAGE!'
              );

              return false;
            }
          }

          if (
            validator === 'module_files_not_empty' &&
            this.$parent.$refs.filesComposer
          ) {
            if (
              (this.$parent.$refs.filesComposer?.model?.module_files &&
                !this.$parent.$refs.filesComposer?.model?.module_files
                  .length) ||
              !this.$parent.$refs.filesComposer?.model?.module_files
            ) {
              this.$store.dispatch(
                'informUserError',
                'Please provide at least one MODULE FILE!'
              );

              return false;
            }
          }

          if (
            validator === 'module_items_not_empty' &&
            this.$parent.currentModule &&
            this.$parent.currentModule.module_items
          ) {
            if (
              ((this.$parent.mapping.module_items &&
                !this.$parent.mapping.module_items.length) ||
                !this.$parent.mapping.module_items) &&
              !this.$parent.mapping.product_filter_id
            ) {
              this.$store.dispatch(
                'informUserError',
                'Please provide at least one MODULE ITEM!'
              );

              return false;
            }
          }
        }

        return this.$helpers.validate(this.$parent.$refs);
      } else {
        return this.$helpers.validate(this.$parent.$refs);
      }
    },
  },
  async created() {
    if (this.isReadMode || this.isUpdateMode) {
      this.loading = true;

      const id = +(this.$route.params.id || this.$route.query.id);

      let variables = {
        id,
      };

      if (this.options && this.options.queryOverrideParams) {
        variables = this.options.queryOverrideParams;
      }

      const result = await this.$apolloHelper({
        query: this.query,
        fallbackData: {},
        force: true,
        variables,
      });

      this.loading = false;

      if (!result || !result.id) {
        this.handleError(`load ${this.pageName} with id: ` + id);
        return this.$router.push({ name: `All ${this.pageNamePlural}` });
      }

      // Need this not to mutate the response object. which is read-only
      const modelChanged = await this.$emit(
        'modelChange',
        this.$helpers.deepClone(result, { omitTypename: true })
      );
      if (modelChanged) {
        document.body.scrollIntoView();
      }
    }
  },
};
</script>

<style>
.form-group.valid input:not([type='checkbox']),
.form-group.valid select,
.form-group.valid textarea {
  border: 1px solid #ccc;
  background-color: #fff;
}
</style>
