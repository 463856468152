<template>
  <div class="wrapper">
    <prism-editor :code="value" v-model="value" language="html"></prism-editor>
  </div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator';
import PrismEditor from 'vue-prism-editor';

export default {
  mixins: [VueFormGenerator.abstractField],
  components: {
    PrismEditor,
  },
  data() {
    return {
      config: {},
    };
  },
};
</script>
