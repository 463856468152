var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('v-select',{ref:"vSelect",attrs:{"loading":_vm.asyncQueryActive,"onSearch":_vm.onSearch,"inputId":_vm.getFieldID(_vm.schema),"multiple":_vm.schema.multiple,"value":_vm.context,"onChange":_vm.onChange,"disabled":_vm.schema.readonly || _vm.schema.disabled,"options":_vm.options,"placeholder":_vm.schema.placeholder},scopedSlots:_vm._u([{key:"selected-option-container",fn:function({ option, deselect }){return _c('div',{staticClass:"d-flex w-100 justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center pl-2"},[(option.default_image)?_c('img',{staticClass:"product-image",attrs:{"src":option.default_image.image
              ? option.default_image.image.file_url
              : '/img/no-image.png'}}):_vm._e(),(option.breadcrumb_image)?_c('img',{staticClass:"product-image",attrs:{"src":option.breadcrumb_image.file_url
              ? option.breadcrumb_image.file_url
              : '/img/no-image.png'}}):_vm._e(),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"m-0"},[_c('strong',[_vm._v(_vm._s(option.label))])]),(option.product_number)?_c('p',{staticClass:"m-0"},[_vm._v(" Product Number: "+_vm._s(option.product_number)+" ")]):_vm._e(),(option.supplier_product_number)?_c('p',{staticClass:"m-0"},[_vm._v(" Supplier Product Number: "+_vm._s(option.supplier_product_number)+" ")]):_vm._e(),(option.price_gross)?_c('p',{staticClass:"m-0"},[_vm._v(" Price: "+_vm._s(option.price_gross / 100)+" ")]):_vm._e()])]),(!_vm.schema.hideAction)?_c('button',{staticClass:"btn btn-default",on:{"click":function($event){$event.preventDefault();return deselect(option)}}},[_c('i',{staticClass:"fa fa-trash",attrs:{"aria-hidden":"true"}})]):_vm._e()])}},{key:"option",fn:function({
        label,
        default_image,
        breadcrumb_image,
        product_number,
        supplier_product_number,
        ean,
        product_texts,
        price_gross,
      }){return _c('dir',{staticClass:"d-flex justify-content-start align-items-center p-0"},[(default_image)?_c('img',{staticClass:"product-image",attrs:{"src":default_image.image
            ? default_image.image.file_url
            : '/img/no-image.png'}}):_vm._e(),(breadcrumb_image)?_c('img',{staticClass:"product-image",attrs:{"src":breadcrumb_image.file_url
            ? breadcrumb_image.file_url
            : '/img/no-image.png'}}):_vm._e(),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"m-0"},[_c('strong',[_vm._v(_vm._s(label))])]),(product_number)?_c('p',{staticClass:"m-0"},[_vm._v(" Product Number: "+_vm._s(product_number)+" ")]):_vm._e(),(supplier_product_number)?_c('p',{staticClass:"m-0"},[_vm._v(" Supplier Product Number: "+_vm._s(supplier_product_number)+" ")]):_vm._e(),(price_gross)?_c('p',{staticClass:"m-0"},[_vm._v("Price: "+_vm._s(price_gross / 100))]):_vm._e()])])}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }