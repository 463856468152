import axios from 'axios';
// import formUrlencoded from 'form-urlencoded';
import Vue from 'vue';

import Me from '@/apollo/users/queries/me.graphql';

const APP_SERVER_URL = process.env.VUE_APP_SERVER_URL;

export let user = null;

function encodeFormData(data) {
  const params = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      params.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      );
    }
  }

  return params.join('&');
}

export default {
  login(email, password, rememberMe, cb) {
    cb = arguments[arguments.length - 1];
    if (localStorage.token) {
      if (cb) cb(true);
      this.onChange(true);
      return;
    }

    axios
      .post(
        `${APP_SERVER_URL}/bo-login?backoffice=true`,
        encodeFormData({
          email: email,
          password: password,
          remember: rememberMe,
        }),
        {
          withCredentials: true,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data && response.data.id) {
          localStorage.token = response.data.id;
          localStorage.email = response.data.email;
          localStorage.privileges = response.data.privilegeList;
          localStorage.isPartnerUser = response.data.isPartnerUser;

          if (cb) {
            cb(true);
          }
          this.onChange(true);
        }
      })
      .catch((error) => {
        /* eslint-disable-line */
        if (cb) {
          cb(false);
        }
        this.onChange(false);
      });
  },

  getToken() {
    return localStorage.token;
  },

  getUserEmail() {
    return localStorage.email;
  },

  reset() {},

  logout() {
    // we always logout from backoffice, no matter if logout on api was successfull or not
    delete localStorage.token;
    delete localStorage.email;
    delete localStorage.privileges;
    delete localStorage.isPartnerUser;

    return axios
      .post(
        `${APP_SERVER_URL}/logout`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.onChange(true);
          return true;
        }
      })
      .catch((error) => {
        /* eslint-disable-line */
        this.onChange(false);
        return false;
      });
  },

  async loggedIn() {
    // additional check against API if user is stil authenticated
    const result = await Vue.$apolloHelper({
      query: Me,
      force: true,
    });

    if (!result.id) {
      delete localStorage.token;
      delete localStorage.email;

      return false;
    }

    user = result;

    return !!localStorage.token;
  },

  onChange() {},
};
