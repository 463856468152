<template>
  <div>
    <div class="generic-table-grid">
      <div style="font-size: 18px;">
        <vuetable-pagination-info
          ref="paginationInfoTop"
        ></vuetable-pagination-info>
      </div>
      <div style="padding-top:20px; padding-bottom: 25px;">
        <vuetable-pagination
          ref="paginationTop"
          :css="css.pagination"
          @vuetable-pagination:change-page="onChangePage"
        />
        <div class="d-flex mt-5 mt-md-0">
          <vuetable-pagination-dropdown
            ref="paginationDropdownTop"
            @vuetable-pagination:change-page="onChangePage"
            class="pt-2 pt-md-0"
          />
          <div class="d-flex pt-2 pt-md-0 pl-3">
            <label class="pr-2 mb-0" v-if="editablePerPage">Per Page:</label>
            <select v-if="editablePerPage" v-model="per_page">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>

      <vuetable
        ref="vuetable"
        :css="css.table"
        :api-mode="false"
        :fields="fields"
        :data-manager="dataManager"
        pagination-path="pagination"
        @vuetable:pagination-data="onPaginationData"
        @vuetable:cell-dblclicked="onCellDoubleClick || (() => null)"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>
        <div slot="actions" slot-scope="props">
          <ActionButtons
            :rowData="props.rowData"
            :defaultViewRouterLinkTo="defaultViewRouterLinkTo"
            :defaultEditRouterLinkTo="defaultEditRouterLinkTo"
            :onViewItem="
              hideViewButton
                ? null
                : onViewItem
                ? onViewItem
                : defaultViewHandler
            "
            :onEditItem="
              hideEditButton
                ? null
                : onEditItem
                ? onEditItem
                : defaultEditHandler
            "
            :onDeleteItem="
              hideDeleteButton
                ? null
                : onDeleteItem
                ? onDeleteItem
                : defaultDeleteHandler
            "
            :extraActions="extraActions"
          />
        </div>
      </vuetable>
      <div style="padding-top:10px; font-size: 18px;">
        <vuetable-pagination-info
          ref="paginationInfoBottom"
        ></vuetable-pagination-info>
      </div>
      <div style="padding-top:10px">
        <vuetable-pagination
          ref="paginationBottom"
          :css="css.pagination"
          @vuetable-pagination:change-page="onChangePage"
        />
        <div class="d-flex mt-5 mt-md-0">
          <vuetable-pagination-dropdown
            ref="paginationDropdownBottom"
            @vuetable-pagination:change-page="onChangePage"
            class="pt-2 pt-md-0"
          />
          <div class="d-flex pt-2 pt-md-0 pl-3">
            <label class="pr-2 mb-0" v-if="editablePerPage">Per Page:</label>
            <select v-if="editablePerPage" v-model="per_page">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <FullscreenWaitingSpinner
      v-if="loading"
      :blurSelectors="[
        '.generic-table-grid',
        '.btn-group.pull-right',
        '.app-footer',
        '.ps-container',
        '.filter-form',
      ]"
    />
  </div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination';
import VuetablePaginationDropdown from 'vuetable-2/src/components/VuetablePaginationDropdown';
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox'; //XXX: What is the problem here.
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo';
import ActionButtons from '@/components/Grid/ActionButtons';
import vuetableHelper from '@/utils/vuetable';
import pluralize from 'pluralize';
import FullscreenWaitingSpinner from '@/components/spinner/FullscreenWaitingSpinner';

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationDropdown,
    VuetablePaginationInfo,
    VuetableFieldCheckbox,
    ActionButtons,
    FullscreenWaitingSpinner,
  },
  props: {
    pageName: String,
    fields: Array,
    hideViewButton: Boolean,
    hideEditButton: Boolean,
    hideDeleteButton: Boolean,
    onViewItem: Function,
    onEditItem: Function,
    onDeleteItem: Function,
    onEditItemRoute: Function,
    onCellDoubleClick: Function,
    extraActions: Object,
    customQuery: String,
    customDeleteMutation: String,
    variables: Object,
    itemName: {
      type: String,
      default: 'name',
    },
    // Needed to add "Per Page" dropdown
    editablePerPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const pluralizedRaw = pluralize(this.pageName).replace(/\s/g, '');
    const pluralized =
      pluralizedRaw.charAt(0).toLowerCase() + pluralizedRaw.slice(1);
    const pageNameFinal = this.pageName.replace(/\s/g, '');

    let data = {
      css: vuetableHelper.bootstrap4,
      loading: false,
      per_page: 25,
      query: this.customQuery
        ? require(`@/apollo/${pluralized}/queries/${this.customQuery}.graphql`)
        : require(`@/apollo/${pluralized}/queries/Get${pluralizedRaw}.graphql`),
    };

    if (!this.hideDeleteButton) {
      data['deleteMutation'] = this.customDeleteMutation
        ? require(`@/apollo/${pluralized}/mutations/${this.customDeleteMutation}.graphql`)
        : require(`@/apollo/${pluralized}/mutations/Delete${pageNameFinal}.graphql`);
    }

    return data;
  },
  methods: {
    async serverRequest(sortOrder, pagination) {
      this.loading = true;
      const serverResponse = await this.$apolloHelper({
        fallbackData: { results: [], pageInfo: {} },
        query: this.query,
        force: true,
        variables: {
          per_page: this.editablePerPage ? +this.per_page : undefined,
          page: pagination.current_page,
          ...this.variables,
        },
      });

      this.loading = false;

      let result = this.$helpers.deepClone(serverResponse, {
        omitTypename: true,
      });

      // TODO: real sorting
      if (sortOrder.length > 0) {
        const sortKey = sortOrder[0].sortField;
        this.$helpers.sortByKey(
          result.results,
          sortKey,
          sortOrder[0].direction === 'asc'
        );
      }

      return {
        pageInfo: result.pageInfo,
        data: this.$parent.mapper
          ? this.$parent.mapper(result.results)
          : result.results,
      };
    },
    async dataManager(sortOrder, pagination) {
      let local = await this.serverRequest(sortOrder, pagination);
      const vt = this.$refs.vuetable;

      if (local && local.pageInfo && vt) {
        pagination = vt.makePagination(
          local.pageInfo.total_count,
          !this.editablePerPage ? local.pageInfo.per_page : +this.per_page
        );
      }
      this.$refs.vuetable.setData({
        pagination: pagination ? pagination : null,
        data: local.data,
      });
    },
    onPaginationData(paginationData) {
      this.$refs.paginationTop.setPaginationData(paginationData);
      this.$refs.paginationDropdownTop.setPaginationData(paginationData);
      this.$refs.paginationInfoTop.setPaginationData(paginationData);
      this.$refs.paginationBottom.setPaginationData(paginationData);
      this.$refs.paginationDropdownBottom.setPaginationData(paginationData);
      this.$refs.paginationInfoBottom.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    defaultViewHandler(data) {
      this.$router.push({
        name: `View ${this.pageName}`,
        params: { id: data.id },
      });
    },
    defaultViewRouterLinkTo(data) {
      return {
        name: `View ${this.pageName}`,
        params: {
          id: data.id,
        },
      };
    },
    defaultEditHandler(data) {
      let routeData = this.$router.resolve({
        name: `Edit ${this.pageName}`,
        params: { id: data.id },
        query: data.channel_id && {
          channel_id: data.channel_id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    defaultEditRouterLinkTo(data) {
      if (this.onEditItemRoute) {
        return this.onEditItemRoute(data);
      }

      return {
        name: `Edit ${this.pageName}`,
        params: {
          id: data.id,
        },
        query: data.channel_id && {
          channel_id: data.channel_id,
        },
      };
    },
    async defaultDeleteHandler(data) {
      if (this.hideDeleteButton) {
        alert('Removal is not supported here!');
        return;
      }

      const result = confirm('Remove?\n' + data[this.itemName]);
      if (result) {
        this.loading = true;
        const serverResponse = await this.$apolloHelper({
          fallbackData: {},
          mutation: this.deleteMutation,
          variables: {
            id: data.id,
          },
        });
        this.loading = false;
        if (
          serverResponse.success === false ||
          (!serverResponse.success &&
            typeof serverResponse.count !== 'number') ||
          (!serverResponse.success && serverResponse.count === 0)
        ) {
          const [{ message }] = serverResponse.errors || [
            { message: `Deleting item ${data[this.itemName]} failed.` },
          ];
          this.$store.dispatch('informUserError', message);
        } else if (serverResponse.count === -1) {
          this.$store.dispatch(
            'informUserError',
            `Item ${
              data[this.itemName]
            } is referenced by other resources, can't be removed.`
          );
        } else {
          this.$store.dispatch(
            'informUserSuccess',
            `Successfully removed item ${
              data[this.itemName]
            }. Please wait for the grid to update`
          );
          this.refreshGrid(false, true);
        }
      }
    },
    refreshGrid(changePage = false, isDeleting = false) {
      const vt = this.$refs.vuetable;

      if (vt) {
        if (changePage || (isDeleting && vt.tableData.length === 1)) {
          vt.currentPage = 1;
        }
        vt.dataManager(vt.sortOrder, vt.makePagination());
      }
    },
  },
  watch: {
    per_page(oldVal, newVal) {
      if (this.editablePerPage) {
        Vue.nextTick().then(() => this.refreshGrid(false, false));
      }
    },
  },
};
</script>
