import pluralize from 'pluralize';

export default (routeName, createViewRoute = false, prependPath = '') => {
  const routeNamePlural = pluralize(routeName);
  const CamelCase = routeName.replace(/\s/g, '');
  const CamelCasePlural = routeNamePlural.replace(/\s/g, '');
  const viewEditOneComponent = () =>
    import(`@/views/${CamelCase}/${CamelCase}`);
  const viewAllComponent = () =>
    import(`@/views/${CamelCase}/${CamelCasePlural}`);

  const path = routeNamePlural.toLowerCase().replace(/\s/g, '_');

  const result = {
    path: `${prependPath}/${path}`,
    redirect: `${prependPath}/${path}/all`,
    name: routeNamePlural,
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: 'all',
        name: `All ${routeNamePlural}`,
        component: viewAllComponent,
      },
      {
        path: 'new',
        name: `New ${routeName}`,
        component: viewEditOneComponent,
      },
      {
        path: ':id/edit',
        name: `Edit ${routeName}`,
        component: viewEditOneComponent,
      },
    ],
  };
  if (createViewRoute) {
    result.children.push({
      path: ':id/view',
      name: `View ${routeName}`,
      component: viewEditOneComponent,
    });
  }

  return result;
};
