var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form',{staticClass:"standard-edit-create-form",class:{ 'view-mode-form': _vm.pageMode === _vm.pageModes.READ }},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[(!(_vm.options && _vm.options.hideHeader))?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('strong',[_vm._v(_vm._s(_vm.pageTitle))]),_vm._t("header")],2),_vm._t("first")],2):_vm._e(),_vm._t("default")],2)],1)],1),_c('div',{staticClass:"standard-edit-create-form__action-button mb-3",staticStyle:{"position":"sticky","bottom":"10px","z-index":"100"}},[_c('b-button',{staticClass:"btn-block",attrs:{"size":"lg","variant":'success',"disabled":_vm.disableSubmitWithNotice.length > 0,"title":_vm.disableSubmitWithNotice},on:{"click":function($event){_vm.pageMode !== _vm.pageModes.READ && !(_vm.options && _vm.options.forceGoBack)
          ? _vm.createOrEdit()
          : _vm.goBack()}}},[_c('i',{class:_vm.pageMode !== _vm.pageModes.READ && !(_vm.options && _vm.options.forceGoBack)
            ? 'fa fa-save'
            : 'fa fa-eye-slash'}),_vm._v(" "+_vm._s(_vm.submitButtonName)+" ")])],1),(_vm.loading)?_c('FullscreenWaitingSpinner',{attrs:{"retryEnabled":true,"blurSelectors":[
      '.standard-edit-create-form',
      '.standard-edit-create-form__action-button',
      '.app-footer',
      '.ps-container',
    ]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }