import apollo from "@/apollo"
import apolloHelperFactory from "./apolloHelperFactory"

export default {
  install(Vue) {
    const $apollo = apolloHelperFactory(apollo)
    Vue.prototype.$apolloHelper = $apollo
    Vue.$apolloHelper = $apollo
  }
}
