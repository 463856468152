<template>
  <div class="wrapper">
    <v-select
      ref="vSelect"
      :inputId="getFieldID(schema)"
      :multiple="schema.multiple"
      :value="context"
      :onChange="onChange"
      :disabled="schema.readonly || isDisabled"
      :options="options"
      :placeholder="schema.placeholder"
    ></v-select>
  </div>
</template>

<script>
//https://sagalbot.github.io/vue-select/docs/Api/Props.html
import VueFormGenerator from 'vue-form-generator';

export default {
  mixins: [VueFormGenerator.abstractField],
  data() {
    return {
      options: [],
      context: null,
      asyncQueryReady: false,
    };
  },
  computed: {
    // TODO: The method name could be changed
    isIntegerModel() {
      return (
        this.schema.validatorName &&
        ['integer', 'integerArray', 'stringArray'].includes(
          this.schema.validatorName
        )
      );
    },
    isModulesSelect() {
      return (
        this.schema.model &&
        ['module_groups', 'module_groups_top', 'module_groups_bottom'].includes(
          this.schema.model
        )
      );
    },
    isDisabled() {
      return typeof this.schema.disabled === 'function'
        ? this.schema.disabled(this.model, this.schema, this)
        : this.schema.disabled;
    },
  },
  methods: {
    onChange(newValue) {
      const result = !newValue
        ? null
        : !this.schema.multiple
        ? newValue.id
        : this.isIntegerModel
        ? newValue.map(item => item.id)
        : newValue;
      if (this.schema.multiple && result) {
        result.isVueSelect = true;
      }
      this.value = result;
    },
    // Expects array when multiple === true, and id: Int, when false
    setContext() {
      if (
        (!this.value && this.value !== 0) ||
        (this.schema.query && this.asyncQueryReady === false)
      ) {
        this.context = null;
        return;
      }

      const keyMap = {};

      if (this.schema.multiple) {
        if (
          !this.schema.filtersMultiSelect &&
          (this.value.length === 0 || this.value.isVueSelect)
        ) {
          // Avoiding infinite loop for multiple because of non-prmimitive data type
          return;
        }

        this.value.forEach(value => {
          if (this.isIntegerModel) {
            keyMap[value] = value;
          } else {
            keyMap[value.id] = value;
          }
        });
      } else {
        keyMap[this.value] = { id: this.value };
      }

      const result = this.options
        .filter(option => keyMap[option.id])
        .map(option => {
          if (this.isIntegerModel) {
            return option;
          }
          return { ...option, ...keyMap[option.id] };
        });

      // Setting the right order of Module Groups
      if (this.isModulesSelect && this.isIntegerModel && this.schema.multiple) {
        const incomingValues = this.value;

        result.sort((a, b) => {
          return incomingValues.indexOf(a.id) - incomingValues.indexOf(b.id);
        });
      }

      this.context = this.schema.multiple
        ? result
        : result.length
        ? result[0]
        : null;
    },
    modelIdReadyHandler() {
      this.setOptions(this.options);
    },
    setOptions(options) {
      if (this.schema.excludeId) {
        this.options = options.filter(item => item.id !== this.model.id);
      } else {
        this.options = options;
      }
    },
    unpack(serverResponse) {
      if (typeof this.schema.mappingPath === 'function') {
        return this.schema.mappingPath(serverResponse);
      }

      let secondMappingPath = null;

      const mappingPath = this.schema.mappingPath.split('.');

      if (typeof this.schema.secondMappingPath === 'function') {
        return this.schema.secondMappingPath(serverResponse);
      }

      if (this.schema.secondMappingPath) {
        secondMappingPath = this.schema.secondMappingPath.split('.');
      }

      return serverResponse.map(item => ({
        id: item.id,
        label: !secondMappingPath
          ? mappingPath.reduce(
              (result, key) => (result && result[key]) || 'No value',
              item
            )
          : mappingPath.reduce(
              (result, key) => (result && result[key]) || 'No value',
              item
            ) +
            ` ${this.schema.secondMappingPathPrefix}: ` +
            secondMappingPath.reduce(
              (result, key) =>
                (result && result[key]) ||
                this.schema.secondMappingPathEmptyValue,
              item
            ),
      }));
    },
    async makeQuery(query) {
      this.asyncQueryReady = false;

      const serverResponse = await query(this.$apolloHelper, this.model, this);

      this.setOptions(this.unpack(serverResponse));

      this.asyncQueryReady = true;
      this.setContext();
    },
    watchModel(watchModel, cb, immediate) {
      const unwatch = this.$watch(
        typeof watchModel === 'function' ? watchModel : `model.${watchModel}`,
        (value, oldValue) => {
          if (value !== oldValue) cb(value);
        },
        {
          immediate: immediate === false ? false : true,
          deep: true,
        }
      );
      this.$once('hook:beforeDestroy', () => unwatch());
    },
  },
  async created() {
    if (this.schema.query) {
      const queryIsFunction = typeof this.schema.query === 'function';

      if (queryIsFunction) {
        if (this.schema.watchModel) {
          this.watchModel(
            this.schema.watchModel,
            () => {
              if (this.schema.flushOnWatchModel && this.$refs.vSelect) {
                this.$refs.vSelect.clearSelection();
              }
              return this.makeQuery(this.schema.query);
            },
            this.schema.immediate
          );
          if (this.schema.immediateQuery) {
            return this.makeQuery(this.schema.query);
          }
        } else {
          this.makeQuery(this.schema.query);
        }
      } else {
        this.makeQuery(() =>
          this.$apolloHelper({
            fallbackData: [],
            query: this.schema.query,
            // force: true, // Disabled to not let apollo run all vue-select queries again and again
          })
        );
      }
    } else if (this.schema.values) {
      if (typeof this.schema.values === 'function') {
        if (this.schema.watchModel) {
          this.watchModel(
            this.schema.watchModel,
            () => {
              if (this.schema.flushOnWatchModel && this.$refs.vSelect) {
                this.$refs.vSelect.clearSelection();
              }
              this.setOptions(
                this.unpack(this.schema.values(this.model, this.schema))
              );
              if (this.schema.fillAllValues && this.$refs.vSelect) {
                this.$refs.vSelect.clearSelection();
                this.options.map(o => this.$refs.vSelect.select(o.id));
                this.$nextTick(() => {
                  this.$refs.vSelect.open = false;
                  this.$forceUpdate();
                });
              }
            },
            this.schema.immediate
          );
        } else {
          this.setOptions(
            this.unpack(this.schema.values(this.model, this.schema))
          );
        }
      } else {
        this.watchModel(
          () => this.schema.values,
          values => this.setOptions(this.unpack(values)),
          true
        );
      }
      this.asyncQueryReady = true;
    }
  },
  watch: {
    value: {
      handler() {
        if (!this.schema.filtersMultiSelect) {
          this.setContext();
        }
      },
    },
    asyncQueryReady: {
      handler: 'setContext',
    },
    'model.id': {
      handler: 'modelIdReadyHandler',
    },
  },
};
</script>
